import { useEffect, useReducer, useCallback, useMemo } from 'react';

import {
  login as loginWithSupaBase,
  signOut,
  register as registerSupabase,
  getSession,
  recoverPassword as recoverPasswordSupabase,
  updatePassword as updatePasswordSupabaseAuth,
} from 'src/Services/auth';

import { supabaseClient } from 'src/utils/supabaseClient';
import { Roles } from 'src/enumerate/roles';
import { userInfoUpdated, userReset } from 'src/redux/slices/user.slice';
import { getUserProfile, useProfile } from 'src/Services/profile';
import { useLocation } from 'src/Services/location';
import { useCompany } from 'src/Services/company';
import { useTemplate } from 'src/Services/template';
import {
  getActiveSubscription,
  getBillingData,
  getCredits,
  useBillingAddress,
  useCards,
} from 'src/Services/billing';
import { store, useDispatch } from 'src/redux/store';
import { resetUsage, updateQuota } from 'src/redux/slices/usage';
import { billingInfoUpdated, billingReset } from 'src/redux/slices/billing';
import { notificationInfoUpdated } from 'src/redux/slices/notification';
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  UPDATE_COMPANY = 'UPDATE_COMPANY',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.UPDATE_PROFILE]: {
    profile: any;
  };
  [Types.UPDATE_COMPANY]: {
    company: any;
  };

  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.UPDATE_PROFILE) {
    return {
      ...state,
      user: {
        ...state.user,
        profile: action.payload.profile,
      },
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === Types.UPDATE_COMPANY) {
    return {
      ...state,
      user: {
        ...state.user,
        company: action.payload.company,
      },
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { updateProfile } = useProfile();
  const { getLocationsByUserId, getLocationsOwn } = useLocation();
  const reduxDispatch = useDispatch();
  const { getCompany, updateCompany } = useCompany();
  const { getSettingsTemplate } = useTemplate();
  const { getCards } = useCards();
  const { getBillingAddress } = useBillingAddress();

  // const locations = useLocations().locationsData;

  const initialize = useCallback(async () => {
    try {
      let accessToken = sessionStorage.getItem(STORAGE_KEY);
      let session = null;

      if (!accessToken || !isValidToken(accessToken)) {
        const {
          data: { session: supabaseSession },
          error: sessionError,
        } = await supabaseClient.auth.getSession();
        if (sessionError) throw new Error(sessionError.message);
        if (!supabaseSession) throw new Error('No session');
        session = supabaseSession;
        accessToken = session.access_token;
      }

      const {
        data: { user },
        error: userError,
      } = await supabaseClient.auth.getUser(accessToken);
      if (userError) throw new Error(userError.message);

      const company = (await getCompany()) as any;
      const credits = await getCredits(company?.id);
      const profile = await getUserProfile(user?.id as string);
      const billing_address = await getBillingAddress();
      const billing = await getBillingData();
      const active_plans = await getActiveSubscription(company?.id);
      const user_cards = await getCards();
      const template_settings = await getSettingsTemplate(user?.id);
      const locations = await getLocationsByUserId(user?.id as string);
      const locationsOwn = await getLocationsOwn(user?.id as string);

      const finaleUser = {
        ...user,
        locations,
        profile,
        company,
        template_settings,
        my_locations: locationsOwn,
        credits,
      };
      reduxDispatch(
        updateQuota({
          email: {
            used: credits.email_credits?.monthly_credits || 0,
            quota: credits.email_credits?.max_credits || 0,
          },
          sms: {
            used: credits.sms_credits?.monthly_credits || 0,
            quota: credits.sms_credits?.max_credits || 0,
          },
        })
      );

      reduxDispatch(
        billingInfoUpdated({
          active_plan: active_plans,
          plans: billing,
          billing_address,
          user_cards,
        })
      );

      reduxDispatch(userInfoUpdated(finaleUser));

      dispatch({
        type: Types.INITIAL,
        payload: { user: finaleUser },
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: { user: null },
      });
      reduxDispatch(userReset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDispatch]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const locations = store.getState()?.user?.locations;
    console.log('🚀 ~ useEffect ~ l1ocations:', locations?.map((loc: any) => loc.id)?.join(','));
    supabaseClient
      .channel('table-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'plans',
        },

        /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
        async (payload) => {
          const company = (await getCompany()) as any;
          const credits = await getCredits(company?.id as string);
          reduxDispatch(
            updateQuota({
              email: {
                used: credits.email_credits?.monthly_credits || 0,
                quota: credits.email_credits?.max_credits || 0,
              },
              sms: {
                used: credits.sms_credits?.monthly_credits || 0,
                quota: credits.sms_credits?.max_credits || 0,
              },
            })
          );
          const billing_address = await getBillingAddress();
          const billing = await getBillingData();
          const user_cards = await getCards();
          const active_plans = await getActiveSubscription(company?.id as string);
          reduxDispatch(
            billingInfoUpdated({
              active_plan: active_plans,
              plans: billing,
              billing_address,
              user_cards,
            })
          );
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'subscriptions',
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (payload: any) => {
          const company = (await getCompany()) as any;
          if (payload?.new?.payment_status === 'succeeded' || payload?.status !== 'active') {
            console.log('payment status succeeded');
            const credits = await getCredits(company?.id as string);
            reduxDispatch(
              updateQuota({
                email: {
                  used: credits.email_credits?.monthly_credits || 0,
                  quota: credits.email_credits?.max_credits || 0,
                },
                sms: {
                  used: credits.sms_credits?.monthly_credits || 0,
                  quota: credits.sms_credits?.max_credits || 0,
                },
              })
            );
          } else if (payload?.eventType === 'DELETE') {
            reduxDispatch(
              updateQuota({
                email: {
                  used: 0,
                  quota: 0,
                },
                sms: {
                  used: 0,
                  quota: 0,
                },
              })
            );
          }

          const billing_address = await getBillingAddress();
          const billing = await getBillingData();
          const user_cards = await getCards();
          const active_plans = await getActiveSubscription(company?.id as string);

          reduxDispatch(
            billingInfoUpdated({
              active_plan: active_plans,
              plans: billing,
              billing_address,
              user_cards,
            })
          );
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'payment_cards',
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (payload) => {
          const user_cards = await getCards();
          reduxDispatch(userInfoUpdated({ user_cards }));
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'billing_address',
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        async (payload) => {
          const company = (await getCompany()) as any;
          const credits = await getCredits(company?.id as string);
          reduxDispatch(
            updateQuota({
              email: {
                used: credits.email_credits?.monthly_credits || 0,
                quota: credits.email_credits?.max_credits || 0,
              },
              sms: {
                used: credits.sms_credits?.monthly_credits || 0,
                quota: credits.sms_credits?.max_credits || 0,
              },
            })
          );
          const billing_address = await getBillingAddress();
          const billing = await getBillingData();
          const user_cards = await getCards();
          const active_plans = await getActiveSubscription(company?.id as string);
          reduxDispatch(
            billingInfoUpdated({
              active_plan: active_plans,
              plans: billing,
              billing_address,
              user_cards,
            })
          );
        }
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'user_notifications',
          filter: `location_id=in.(${locations?.map((loc: any) => loc.id)?.join(',') ?? ''})`,
        },
        (payload: any) => {
          console.log('🚀 ~ useEffect ~ payload:', payload);
          if (payload.eventType === 'INSERT') {
            reduxDispatch(notificationInfoUpdated(payload.new));
          } else if (payload.eventType === 'UPDATE') {
            reduxDispatch(notificationInfoUpdated(payload.new));
          }
        }
      )
      .subscribe();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [store.getState()?.user?.locations]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const data = {
        email,
        password,
      };
      const { user, session } = await loginWithSupaBase(data);

      const profile = await getUserProfile(user?.id as string);
      const company = (await getCompany()) as any;
      const credits = await getCredits(company?.id as string);
      const locations = await getLocationsByUserId(user?.id as string);
      const template_settings = await getSettingsTemplate(user?.id as string);
      const locationsOwn = await getLocationsOwn(user?.id as string);
      const billing_address = await getBillingAddress();
      const billing = await getBillingData();
      const active_plans = await getActiveSubscription(company?.id as string);
      const user_cards = await getCards();
      const finaleUser = {
        ...user,
        locations,
        company,
        profile,
        template_settings,
        my_locations: locationsOwn,
        credits,
      };
      reduxDispatch(
        billingInfoUpdated({
          active_plan: active_plans,
          plans: billing,
          billing_address,
          user_cards,
        })
      );
      setSession(session.access_token);

      reduxDispatch(userInfoUpdated(finaleUser));
      reduxDispatch(
        updateQuota({
          email: {
            used: credits.email_credits?.monthly_credits || 0,
            quota: credits.email_credits?.max_credits || 0,
          },
          sms: {
            used: credits.sms_credits?.monthly_credits || 0,
            quota: credits.sms_credits?.max_credits || 0,
          },
        })
      );

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: finaleUser,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reduxDispatch]
  );
  const UpdateCompany = useCallback(async (data: any) => {
    const company_data = await updateCompany(data);

    dispatch({
      type: Types.UPDATE_COMPANY,
      payload: {
        company: company_data,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateProfile = useCallback(
    async (data: any) => {
      try {
        const profile_data = await updateProfile(data);

        const finaleUser = {
          ...state,
          user_metadata: {
            ...state?.user?.user_metadata,
            full_name: profile_data?.name,
          },
          profile: profile_data,
        };

        reduxDispatch(userInfoUpdated(finaleUser));
      } catch (error) {
        console.log(error);
      }
    },
    [reduxDispatch, state, updateProfile]
  );

  const LoginWithSession = useCallback(async () => {
    try {
      const { session } = await getSession();
      setSession(session?.access_token);
      dispatch({
        type: Types.LOGIN,
        payload: {
          user: session?.user,
        },
      });
      userInfoUpdated(session?.user);
    } catch (error) {
      console.log(error);
    }
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string,
      company_name: string
    ) => {
      await registerSupabase({
        email,
        password,
        data: {
          name: `${firstName} ${lastName}`,
          role: Roles.USER,
          company_name,
        },
      });
    },
    []
  );
  // RECOVER LINK FOR REST PASSWORD
  const recover = useCallback(async (email: string) => {
    await recoverPasswordSupabase(email);
  }, []);

  // RECOVER LINK FOR REST PASSWORD
  const reset = useCallback(async (password: string) => {
    await updatePasswordSupabaseAuth(password);
  }, []);
  // LOGOUT
  const logout = useCallback(async () => {
    await signOut();
    sessionStorage.clear(); // Clear session storage
    dispatch({ type: Types.LOGOUT }); // Clear Redux store first
    reduxDispatch(userReset());
    reduxDispatch(billingReset());
    reduxDispatch(resetUsage());
    setSession(null);
  }, [reduxDispatch]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.id ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      UpdateProfile,
      login,
      LoginWithSession,
      UpdateCompany,
      register,
      recover,
      reset,
      logout,
    }),
    [
      state.user,
      status,
      UpdateProfile,
      login,
      LoginWithSession,
      UpdateCompany,
      register,
      recover,
      reset,
      logout,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
